"use strict";

export function init() {
    let poiMap = $('.js-poi-map');
    let teasers = poiMap.find('.js-poi-map__teaser');
    let staticTeaser = poiMap.find('.js-poi-map__teaser--static');
    let buttons = poiMap.find('.js-poi-map__button');

    buttons.on('click', function (evt) {
        let $this = $(this);
        let id = $this.data('id');
        let teaser = poiMap.find('.js-poi-map__teaser[data-id="' + id + '"]');

        console.log('teaser', teaser);

        if(!teaser.hasClass('is-active')) {
            if (staticTeaser && staticTeaser.hasClass('is-active')) {
                staticTeaser.removeClass('is-active');
            }
            teasers.removeClass('is-active');
            buttons.removeClass('is-active');

            $this.addClass('is-active');
            teaser.addClass('is-active');

        } else {
            $this.removeClass('is-active');
            teaser.removeClass('is-active');
            if (staticTeaser) {
                staticTeaser.addClass('is-active');
            }
        }

        let close = teaser.find('.js-poi-map__close');
        close.on('click', function (e){
            $this.removeClass('is-active');
            teaser.removeClass('is-active');
            if (staticTeaser) {
                staticTeaser.addClass('is-active');
            }
        })
    });
}